.whychooseus_container{
    margin: 1rem 0;
    text-align: center;
    position: relative;
}

.whychooseus_container p{
    margin-top: 1rem;
}


.whychooseus_container h2{
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-color);
    position: relative;
}

.whychooseus_container h2:before{
    content: "";
    position: absolute;
    text-align: center;
    height: 3px;
    width: 80px;
    background-color: var(--primary-color);
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}


.whychoose_section{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 2rem;
    margin: 2rem 0;
}


/* .whychooseus_innersection_container{
    position: absolute;
    top: 25%;
    left: 48%;
} */


.whychooseus_innersection{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: 1rem;
}

.whychooseus_innersection > div{
    width: 240px;
}

.whychooseus_innersection_container .whychooseus_innersection:nth-child(2){
    margin-top: 2rem;
}

.whycooseus_icons{
    /* font-size: 5.2rem; */
    color: #fff;
    background: linear-gradient(90deg, rgba(107,107,251,1) 0%, rgba(107,107,251,0.8267682072829132) 100%);
    border-radius: 50%;
    text-align: center;
    padding: 1rem;
    width: 60px;
    height: 60px;
}

.second{
    background: linear-gradient(90deg, rgba(251,167,10,1) 0%, rgba(251,167,10,0.46262254901960786) 64%);

}

.third{
    background: linear-gradient(90deg, rgba(53,251,10,1) 0%, rgba(88,251,10,0.5718662464985995) 100%);
}

.fourth{
    background: linear-gradient(90deg, rgba(254,100,136,1) 0%, rgba(254,100,136,0.76234243697479) 100%);
}

.whychooseus_innersection p{
    color: gray;
}