


.howitworks_container h2{
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-color);
    position: relative;
}

.howitworks_container h2::before{
    content: "";
    position: absolute;
    text-align: center;
    height: 3px;
    width: 80px;
    background-color: var(--primary-color);
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

  .Tabs {
    width: 90%;
    min-height: 400px;
    /* background: #053742; */
    margin: 2.3rem auto 1.5rem;
    padding: 2rem 1rem;
    /* color: #E8F0F2; */
    /* border-radius: 2rem; */
    @media (max-width: 769px) {
      padding: 2rem 0;
    }
  
  }
  
  /* Tab Navigation */
  ul.nav {
    width: 60%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid var(--primary-color); */
    border-radius: 2rem;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  ul.nav li {
    /* color: #E8F0F2; */
    width: 50%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
  }
  ul.nav li:nth-child(2) {
    border-radius: 0;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }
  ul.nav li:hover {
    /* background: rgba(234, 80, 80, 0.2) */
  }
  ul.nav li.active {
    color: var(--primary-color);
  }
  ul.nav li::after {
    /* background: var(--primary-color); */
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px; 
    text-align: center;
    background-color: transparent;
    transition: transform 0.3s ease, background-color 0.3s ease;
    transform: translateX(-50%) scaleX(0);
  }
  ul.nav li.active::after {
    background-color: var(--primary-color);
    transform: translateX(-50%) scaleX(1); 
  }
  
  /* First and Second Tab Styles */
  .FirstTab p,
  .SecondTab p {
    font-size: 2rem;
    text-align: center;
  }



  .seo_home_area {
    background: #f7fdfc;
    position: relative;

}


.home_bubble .bubble {
  position: absolute;
  border-radius: 50%
}

.home_bubble .bubble.b_one,.home_bubble .bubble.b_four {
  width: 10px;
  height: 10px
}

.home_bubble .bubble.b_two,.home_bubble .bubble.b_five {
  width: 30px;
  height: 30px
}

.home_bubble .bubble.b_three,.home_bubble .bubble.b_six {
  width: 14px;
  height: 14px
}

.home_bubble .bubble.b_one {
  background: #00c99c;
  top: 10%;
  left: 85px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate
}

.home_bubble .bubble.b_two {
  background: #fca249;
  top: 20%;
  left: 155px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin1 2s infinite alternate;
  animation: spin1 2s infinite alternate
}

.home_bubble .bubble.b_three {
  background: #7d95fa;
  top: 50%;
  left: 55px;
  -webkit-animation: spin1 1s infinite alternate;
  animation: spin1 1s infinite alternate
}

.home_bubble .bubble.b_four {
  background: #d27dfa;
  top: 30%;
  right: 85px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin1 2s infinite alternate;
  animation: spin1 2s infinite alternate
}

.home_bubble .bubble.b_five {
  background: #ff9398;
  top: 40%;
  right: 155px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: spin1 3s infinite alternate;
  animation: spin1 3s infinite alternate
}

.home_bubble .bubble.b_six {
  background: #f1d53b;
  top: 85%;
  right: 55px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate
}

.home_bubble .triangle {
  position: absolute
}

.home_bubble .triangle.b_seven {
  left: 25%;
  top: 400px
}

.home_bubble .triangle.b_eight {
  left: -100px;
  bottom: -45px
}

.home_bubble .triangle.b_nine {
  right: 28%;
  bottom: 250px;
  -webkit-animation: spin2 2s infinite alternate;
  animation: spin2 2s infinite alternate
}



@keyframes spin {
  0% {
      -webkit-transform: translateX(-10px) scale(.9);
      transform: translateX(-10px) scale(.9)
  }

  to {
      -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
      transform: translateX(30px) scale(1.3) translateY(10px)
  }
}

@-webkit-keyframes spin1 {
  0% {
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }

  to {
      -webkit-transform: scale(1.5);
      transform: scale(1.5)
  }
}

@keyframes spin1 {
  0% {
      -webkit-transform: scale(.9);
      transform: scale(.9)
  }

  to {
      -webkit-transform: scale(1.5);
      transform: scale(1.5)
  }
}

@-webkit-keyframes spin2 {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  to {
      -webkit-transform: translateY(40px);
      transform: translateY(40px)
  }
}

@keyframes spin2 {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  to {
      -webkit-transform: translateY(40px);
      transform: translateY(40px)
  }
}
