

.logo{
    height: calc(18px + 1.5625vw);
}



.btn{
    display: inline-block;
    margin-top: 1rem;
    padding:.8rem 3rem;
    border-radius: 5rem;
    background:var(--gradient);
    font-size: 1.7rem;
    color:#fff;
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    transition: all .3s linear;
}

.btn:hover{
    transform: scale(1.1);
}



.header{
    position: fixed;
    top:0; left:0;
    width:100%;
    background: transparent;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    padding: 1.2rem 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    transition: all 0.5s ease;

}

.headerfix{
   
    position: fixed;
    top:0; left:0;
    width:100%;
    background:#ffffff;
    opacity: 1;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    padding: 1.2rem 6%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    transition: .3s;
   
}

.header .logo,
.headerfix .logo{
    font-size: 2rem;
    color:var(--purple);
}

.header .logo span,
.headerfix .logo span{
    color:var(--pink);
}



.header .navbar a,
.headerfix .navbar a{
    font-size: 1.7rem;
    font-weight: 600;
    margin-left: 2rem;
    /* color:var(--purple); */
    color: #fff;
}
.header .navbar i,
.headerfix .navbar i{
    font-size: 1.2rem;
    margin-left: 0.3rem;
    color: #fff;
}

.headerfix .navbar a{
    color:var(--purple);
}

.header .navbar a:hover,
.header .navbar i:hover
{
    color:var(--pink);
}

.headerfix .navbar a:hover{
    color:var(--pink);
}

.header input,
.headerfix input{
    display: none;
}

.header label,
.headerfix label{
    font-size: 3rem;
    color:var(--purple);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
}
.navbar{
    position: relative;
}

.dropdown{
    display: block;
    position: absolute; 
    padding: 1rem 1rem;
    background-color: var(--pink);
    margin-left: 17rem;
  
}




@media (max-width:768px){

    

    .header label,
    .headerfix label
    {
        visibility: visible;
        opacity: 1;
    }

    .headerfix .navbar a:checked{
        color:var(--pink);
    }

    .header .navbar,
    .headerfix .navbar{
        position: absolute;
        top:100%; left: 0;
        width:100%;
        background:#fff;
        padding:1rem 2rem;
        border-top: .1rem solid rgba(0,0,0,.2);
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
        transform-origin: top;
        transform: scaleY(0);
        opacity: 0;
        transition: .2s linear;
    }

    .header{
        background: #fff;
    }

    .header .navbar a,
    .headerfix .navbar a{
        display: block;
        margin:2rem 0;
        font-size: 2rem;
        color: var(--purple);
    }

    .header input:checked ~ .navbar,
    .headerfix input:checked ~ .navbar{
        transform: scaleY(1);
        opacity: 1;
    }


}

