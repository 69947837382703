:root{
  --primary-color: rgba(246, 54, 93, 0.8);
}

*{
  font-family: 'Poppins', sans-serif, 'Roboto';
  margin:0; padding:0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  text-transform: none;
}

body {
  margin: 0;
  background-color: #fcfcfceb;
}


main{
  margin-top: 1rem;
}

a{
  cursor: pointer;
  
}

img{
  user-select: none;
  -webkit-user-drag: none;
}