.industry-container {
    text-align: center;
    padding: 20px;
    margin: 3rem 0 1rem;
  }
  
  
  .industry-container h2{
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-color);
    position: relative;
}

.industry-container h2:before{
    content: "";
    position: absolute;
    text-align: center;
    height: 3px;
    width: 80px;
    background-color: var(--primary-color);
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}


  .industry-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .industry-item {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin: 10px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 200px;
    text-align: center;
    background-color: #f9f9f9;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .industry-item:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .industry-item img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  /* Add more styles as needed */
  