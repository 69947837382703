.footer-container{

    /* background-color: #1B1E24; */
    /* margin-top: 1rem; */
    /* padding: 1rem 2rem; */
    color: #696969;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    /* color: #d2d2d2; */
}

.footer-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 1rem;
    justify-content: center;
    column-gap: 60px;
    padding: 0 0 3.5rem;
}

.footer-content > div:first-child{
    width: 440px;
}

.footer-content > div:nth-child(2){
    width: 140px;
}
.footer-content > div h5{
    /* color: #d2d2d2; */
    color: rgb(11, 11, 11);
}

.footer-content > div p{
    margin-top: 6px;
}


.footer-content > div:nth-child(2) p:hover,.footer-content > div:nth-child(3) p:hover{
    cursor: pointer;
    color: var(--primary-color);
}


.footer-content h5{
    font-size: 1.2rem;
}

.copyrightsection{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1rem;
    padding: 12px 2rem 1rem;
}

.social-icon{
    width: 40px;
    height: 40px;
    border: 1px solid #696969;
    /* border: 1px solid #d2d2d2; */
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #696969;
    /* color: #d2d2d2; */
    margin-right: 10px;
    padding: 9px;
    cursor: pointer;
}

.social-icon:hover{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #fff;

}