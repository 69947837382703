
.privacypolicy{
    margin-top: 6rem;

}

.privacypolicy h1{
   text-align: center;
   margin-bottom: 2rem;
   font-size: 3rem;
}

.privacypolicy p{
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 32px;
}

.privacypolicy h2,h5{
    margin-top: 1rem;
}

.privacypolicy h2{
    font-size: 2rem;
}

.privacy-content{
    margin: 0 5rem;
}


@media screen and (max-width:745px){
    .privacy-content{
        margin: 0 2rem;
    }
}