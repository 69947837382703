.form-container{
    display:flex;
    justify-content: center;
    align-items: center;
}

.login-container{
    position: relative;
    max-width: 850px;
    width: 100%;
    background: #fff;
    padding: 40px 30px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    /* perspective: 2700px; */
    margin: 7rem 0;
  }
  .login-container .cover{
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 50%;
    z-index: 98;
    transition: all 1s ease;
    transform-origin: left; 
    transform-style: preserve-3d;
  }
  .login-container #flip:checked ~ .cover{
    transform: rotateY(-180deg);
  }
   .login-container .cover .front,
   .login-container .cover .back{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .cover .back{
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }
  .login-container .cover::before,
  .login-container .cover::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: var(--primary-color); */
    /* opacity: 0.5; */
    z-index: 12;
  }
  .login-container .cover::after{
    /* opacity: 0.3; */
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }
  .login-container .cover img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 10;
  }
  .login-container .cover .text{
    position: absolute;
    z-index: 130;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cover .text .text-1,
  .cover .text .text-2{
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  .cover .text .text-2{
    font-size: 15px;
    font-weight: 500;
  }
  .login-container .forms{
    height: 100%;
    width: 100%;
    background: #fff;
  }
  .login-container .form-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .form-content .login-form,
  .form-content .signup-form{
    width: calc(100% / 2 - 25px);
  }
  .forms .form-content .title{
    position: relative;
    font-size: 24px;
    font-weight: 500;
    color: #333;
  }
  .forms .form-content .title:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 25px;
    background: var(--primary-color);
  }
  .forms .signup-form  .title:before{
    width: 20px;
  }
  .forms .form-content .input-boxes{
    margin-top: 30px;
  }
  .forms .form-content .input-box{
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    margin: 10px 0;
    position: relative;
  }
  .form-content .input-box input{
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    transition: all 0.3s ease;
  }
  .form-content .input-box input:focus,
  .form-content .input-box input:valid{
    border-color: var(--primary-color);
  }
  .form-content .input-box i{
    position: absolute;
    color: var(--primary-color);
    font-size: 17px;
  }
  .forms .form-content .text{
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
  .forms .form-content .text a{
    text-decoration: none;
  }
  .forms .form-content .text a:hover{
    text-decoration: underline;
  }
  .forms .form-content .button{
    text-align: center;
    color: #fff;
    margin-top: 40px;
  }
  .forms .form-content .button input{
    color: #fff;
    background: var(--primary-color);
    border-radius: 6px;
    padding: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    text-align: center;

  }
  .forms .form-content .button input:hover{
    background: var(--primary-color);
  }
  .forms .form-content label{
    color: var(--primary-color);
    cursor: pointer;
  }
  .forms .form-content label:hover{
    text-decoration: underline;
  }
  .forms .form-content .login-text,
  .forms .form-content .sign-up-text{
    text-align: center;
    margin-top: 25px;
  }
  .login-container #flip{
    display: none;
  }
  @media (max-width: 730px) {
    .login-container .cover{
      display: none;
    }
    .form-content .login-form,
    .form-content .signup-form{
      width: 100%;
    }
    .form-content .signup-form{
      display: none;
    }
    .login-container #flip:checked ~ .forms .signup-form{
      display: block;
    }
    .login-container #flip:checked ~ .forms .login-form{
      display: none;
    }
  }

.error{
    text-align: center;
    color: red;
}


.success{
    text-align: center;
    color: green;
}

.info{
    color: gray;
    font-size: 12px;
    margin-top: 12px;
}