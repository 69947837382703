
 
.blog_head{
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-color);
    position: relative;
    margin: 2rem 0 2.6rem;
}

.blog_head:before{
    content: "";
    position: absolute;
    text-align: center;
    height: 3px;
    width: 80px;
    background-color: var(--primary-color);
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.blog_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .card {
    /* margin: 10px; */
    background-color: #ffffff;
    border: 1px solid #ececec;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    overflow: hidden;
    width: 350px;
  }

  

  .card-header img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }

  .card-body h4{
    font-size: 1.1rem;
    margin: 7px 0 4px;
  }

  
  .tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .tag-teal {
    background-color: #47bcd4;
  }
  .tag-purple {
    background-color: #5e76bf;
  }
  .tag-pink {
    background-color: #cd5b9f;
  }
  
  .card-body p {
    font-size: 13px;
    margin: 0 0 30px;
  }
  .user {
    display: flex;
    margin-top: auto;
  }
  
  .user img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info h5 {
    margin: 0;
  }
  .user-info small {
    color: #545d7a;
  }
  