
.retailer_container{
    text-align: center;
}

.retailer_container h3{

    font-size: 1.6rem;
	color: rgba(34, 33, 33, 0.879);

}

.s-heading p{
	color: rgba(61, 61, 61, 0.6);
	font-size: 1.4rem;
	margin: 5px;
	text-align: center;
}

.icon{
    font-size: 3rem;
    color: var(--primary-color);
}

.s-box-container{
	width:100%;
    margin-top: 2rem;
	display: flex;
    flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
.s-box{
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	width:300px;
	padding: 14px 25px;
	height: 300px;
	box-sizing: border-box;
	margin: 5px 30px;
	position: relative;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    /* border: 1px solid rgba(97, 97, 97, 0.9); */
	/* box-shadow: 2px 2px 30px rgba(0,0,0,0.08); */

}
.s-box img{
	height: 75px;
}
.s-box h1{
    color: var(--primary-color);
	letter-spacing: 1px;
	font-size: 1.1rem;
	margin: 12px 0;
    text-align: center;
	
}
.s-box p{
	color: rgba(61, 61, 61, 0.6);
	font-size: 1rem;
	text-align: center;
}

.bar{
	width: 100px;
	height: 6px;
	position: absolute;
	left: 50%;
	top: 0%;
	transform: translateX(-50%);
	background-color: var(--primary-color); 
	border-radius: 0px 0px 10px 10px;
	display: none;
	animation: bar 0.5s;
}
.s-box:hover{
	box-shadow: 2px 2px 30px rgba(0,0,0,0.08);
	transition: all ease 0.3s;
}

.s-box:hover .bar{
	display: block;
}

@keyframes bar{
	0%{
		width:0px;
	}
	100%{
		width:100px;
	}
}
@media(max-width:1050px){
	.s-box-container{
		flex-wrap: wrap;
		
	}	
	
	.s-box{
        
		flex-grow: 1;
        height: 250px;
    
    }
}


