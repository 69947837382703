.infobar{
    background-color: #2c3443;
    width: 100%;
    height: auto;
    min-height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.infobar h3{
   color: aliceblue;
   font-size: 2.5rem;
}


.infobar h4{
    color:rgb(240, 240, 240);
 }



.button-18 {
  align-items: center;
  background-color: #e83a3a;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin-top: 1rem;
}

.button-18:hover { 
  background-color: #c32c2c;
  color: #ffffff;
}

/* .button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
} */

.button-18:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}