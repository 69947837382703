
.downloadbgSection{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    color: #808080;
   /* margin-top: 3rem; */
}

.downloadsbg{
    max-width: 550px;
}

.downloadsbg h2{
    color: #313131;
    font-size: 2.5rem;
    font-weight: bold;

}

.downloadsbg p{
    color: #313131;
    font-size: 1rem;
    font-weight: bolder;
}

.downloadsbg span{
    color: var(--primary-color);
}

.downloadsbg p:nth-child(2){
    font-weight: 300;
}

.downloadsbg p:nth-child(3){
    margin-top: 1rem;
    font-size: 1.1rem;
}

.whysectionbtn{
    margin-top: 1rem;
}

.whysectionimg{
    width: 40vw;
}

@media(max-width: 940px){
    .downloadbgSection{
        margin-top: 5rem;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        row-gap: 2.3rem;
        padding: 3rem 0;
    }
    
    .downloadsbg{
        margin: 0 1.8rem;
    }

}


.round-shape-one {
    width: 20px;
    height: 20px;
    border: 3px solid #fe8f8f;
    border-radius: 50%;
    position: absolute;
    top: 15%;
    left: 36%;
    z-index: -1;
    animation: animationFramesOne 15s infinite linear;
}


@keyframes animationFramesOne {
    0% {
        transform: translate(0,0) rotate(0) scale(1);
    }
    
    20% {
        transform: translate(73px,-1px) rotate(36deg) scale(.9);
    }
    40% {
        transform: translate(141px,72px) rotate(72deg) scale(1);
    }
    60% {
        transform: translate(83px,122px) rotate(108deg) scale(1.2);
    }
    80% {
        transform: translate(-40px,72px) rotate(144deg) scale(1.1);
    }
    100% {
        transform: translate(0,0) rotate(0) scale(1);
    }
}


.round-shape-two {
    width: 7px;
    height: 7px;
    background: #d5eeff;
    border-radius: 50%;
    position: absolute;
    top: 23%;
    left: 8%;
    z-index: -1;
    animation: animationFramesTwo 13s infinite linear;
}

.round-shape-three {
    position: absolute;
    width: 8px;
    height: 8px;
    border: 2px solid #a3eead;
    border-radius: 50%;
    bottom: 42%;
    left: 6%;
    z-index: -1;
    animation: animationFramesTwo 15s infinite linear;
}

@keyframes animationFramesTwo {
    0% {
        transform: translate(0,0) rotate(0) scale(1);
    }
    20% {
        transform: translate(73px,-1px) rotate(36deg) scale(.9);
    }
    40% {
        transform: translate(141px,72px) rotate(72deg) scale(1);
    }
    60% {
        transform: translate(83px,122px) rotate(108deg) scale(1.2);
    }
    80% {
        transform: translate(-40px,72px) rotate(144deg) scale(1.1);
    }

    100% {
        transform: translate(0,0) rotate(0) scale(1);
    }

}


.shape-two {
    position: absolute;
    bottom: 12%;
    left: 31%;
    z-index: -1;
    animation: rotated 20s infinite linear;
}

@keyframes rotated{
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}